<template>
  <div class="vx-row" style="margin-top: 10px;">
    <div class="vx-col md:w-1/1 w-full mb-[160px]">
      <vx-card title="Form Tax Invoice">
        <div class="vx-row mb-6 mx-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Company</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <formInputs :components="this.formCompany.inputs"/>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Tax Invoice Period</span>
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <formInputs :components="this.formMonth.inputs"/>
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <formInputs :components="this.formYear.inputs"/>
          </div>
        </div>
        <div class="vx-row mb-6 mx-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Search</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input v-model="table.search" class="w-full"/>
          </div>
        </div>
        <div class="vx-row mb-6 mx-4">
          <div class="vx-col sm:w-4/5 w-full text-center">
            <vs-button class="mr-4" @click="handleView()" color="primary" icon-pack="feather">View
            </vs-button>
            <vs-button @click="handleExport()"
                       :disabled="!this.itemRefs.some((dt) => dt.inputs[1].components.value)" color="danger"
                       icon-pack="feather" type="border">Export
            </vs-button>
          </div>
        </div>
        <vs-row class="mb-6 ml-4 mr-4" vs-w="12">
          <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-align="center" vs-w="12">
            <span>Total Invoice : {{ this.itemRefs.length }} </span>
          </vs-col>
        </vs-row>
        <vs-divider class="mb-2"></vs-divider>
        <vs-tabs :color="colorX" v-model="tabs">
          <vs-tab @click="
                        colorX = 'success';
                    tabClick(0);
                    " label="SN Export">
            <div class="con-tab-ejemplo">
              <datatable :itemRefs="itemRefs" :tablep="table" :modelItemRefInput="ItemRefsHead"
                         @change-length="handleChangeLength"
                         @handleChangePage="handleChangePage" @input-itemref="oninput" @click="handlerClick"
                         @handleSort="handleSort" />
            </div>
          </vs-tab>
          <vs-tab @click="colorX = 'warning';tabClick(1);" label="Data Export">
            <div class="con-tab-ejemplo">
              <export_table :hideSearch="true" type="tax-invoice-cn-claim-mix" />
            </div>
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </div>
  </div>
</template>

<script>
import formInputs from "../form_inputs.vue";
import moment from "moment/moment";
import datatable from "@/views/oms/master/tax_invoice/datatable.vue";
import export_table from "@/views/oms/master/tax_invoice/export/export_table.vue";

export default {
  components: {
    export_table, datatable,
    formInputs,
  },
  data() {
    return {
      tabs: 0,
      colorX: "success",
      formCompany: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "company",
          placeholder: "Company",
          type: "input",
          value: "",
        }
      },
      formMonth: {
        id: 3,
        inputs: this.formInputMonth(),
      },
      formYear: {
        id: 4,
        inputs: this.formInputYear(),
      },
      table: {
        start: 1,
        stripe: true,
        end: 0,
        page: 0,
        length: 10,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
      },
      itemRefs: [],
      ItemRefsHead: {
        id: 2,
        action: false,
        inputs: this.getInputsHead(),
      },
    }
  },
  async mounted() {
    const data = await this.getData()
    this.loadData(data)
  },
  methods: {
    handlerClick(comp) {
      const downloadFile = 10
      if (comp.id_input === downloadFile) {
        const path = comp.href
        const tax_number_id = this.itemRefs[comp.id].tax_number_id
        this.handlerDownload(path, tax_number_id, comp.id)
      }
    },
    resetTable() {
      this.table.total = 0
      this.table.totalPage = 0
      this.table.totalSearch = 0
      this.table.length = 10
      this.table.start = 0
      this.table.end = 0
      this.table.stripe = false
    },
    handleChangeLength(item) {
      if (item === "All") {
        this.table.end = this.table.total
      } else {
        this.table.end = item
      }
      this.table.length = this.table.end
      this.handleView()
    },
    handleChangePage(page) {
      if (this.tab === this.gen) {
        this.table.page = page;
        this.handleView()
      }
    },
    handleSort(order) {
      if (this.table.order === order) {
        if (this.table.sort === "desc") this.table.sort = "asc"
        else this.table.sort = "desc"
      }
      this.table.order = order;
      this.handleView()
    },
    tabClick(i) {
      this.resetTable()
      if (i === 1) {
      } else {
        this.handleView()
      }
    },
    loadData(data) {
      this.formCompany.inputs.value = data.company
      this.formCompany.inputs.disabled = true
    },
    async getData() {
      try {
        this.$vs.loading();
        const params = {
          type: 3,
        }
        const response = await this.$http
          .get(`api/v1/master/tax-invoice`, {params})

        if (response.code !== 200) {
          throw new Error(response.message)
        }

        return response.data
      } catch (error) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: error,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    getInputsHead() {
      const inputs = [];
      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "1",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "checkbox", //checkbox/title
          head_val: false, //for head=checkbox
          type: "checkbox",
          value: false,
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Tax Invoice Date",
          title: "Tax Invoice Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "code",
          placeholder: "Code",
          title: "Code",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "code",
          placeholder: "Tax Invoice Number",
          title: "Tax Invoice Number",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer/Supplier Code",
          title: "Customer/Supplier Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "customer_name",
          placeholder: "Customer/Supplier Name",
          title: "Customer/Supplier Name",
          type: "input",
          value: "",
        },
      });

      return inputs
    },
    getInputs(data = null, i = 0, no = 0) {
      let period = ""
      let cnManualCode = ""
      let customerCode = ""
      let customerName = ""
      let taxNumber = ""

      const inputs = []
      if (data) {
        period  = moment(data.PostingDate).format('YYYY-MM-DD');
        cnManualCode = data.Code
        customerCode = data.CustomerCode
        customerName = data.CustomerName
        taxNumber = data.TaxNumber
      }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });

      inputs.push({
        components: {
          id: i,
          id_input: 1,
          disabled: false,
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "title", //checkbox/title
          type: "checkbox",
          value: false,
        },
      });

      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "tax_invoice_date",
          placeholder: "Tax Invoice Date",
          title: "Tax Invoice Date",
          type: "text",
          value: period,
        },
      })

      inputs.push({
        components: {
          id: i,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "tax_invoice_date",
          placeholder: "DN Code",
          title: "DN Code",
          type: "text",
          value: cnManualCode,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "tax_invoice_date",
          placeholder: "Tax Invoice Number",
          title: "Tax Invoice Number",
          type: "text",
          value: taxNumber,
        },
      });

      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer/Supplier Code",
          title: "Customer/Supplier Code",
          type: "text",
          value: customerCode,
        },
      });

      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "customer_name",
          placeholder: "Customer/Supplier Name",
          title: "Customer/Supplier Name",
          type: "text",
          value: customerName,
        },
      });

      return inputs
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total === 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search !== ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    async handleExport() {
      try {
        this.$vs.loading();
        let month = "00"
        let year = "2022"
        if (this.formMonth.inputs.value) {
          month = this.formMonth.inputs.value.getMonth()
        }
        if (this.formYear.inputs.value) {
          year = this.formYear.inputs.value.getFullYear()
        }

        const data_id = this.itemRefs
          .filter(dt => dt.inputs[1].components.value)
          .map(dt => dt.idInvoice);

        const params = {
          tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
          invoice_type: 4,// Invoice Type DN Trade
          tax_invoice_type: "All",
          type_exp: "csv",
          data_id,
          is_all: false
        }
        const result = await this.$http
          .post(`api/v1/master/tax-invoice/export`, params)

        if (result.code != 200) {
          throw new Error(result.message);
        }
        this.$vs.notify({
          color: "success",
          title: "Success",
          text: result.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.handleView();
      } catch (error) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: error,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async handleView(){
      if (this.formMonth.inputs.value && this.formYear.inputs.value) {
        const dataView = await this.getView()

        this.table.total = dataView.total_record
        this.table.totalPage = dataView.total_page
        this.table.totalSearch = dataView.total_record_search;
        this.table.length = dataView.total_record_per_page
        this.setStartEnd()

        let no = this.table.start
        const dataInvoice = dataView.dataInvoice.map((item, index) => ({
          id: index,
          idInvoice: item.ID,
          action: false,
          inputs: this.getInputs(item, index, no++)
        }))

        this.itemRefs = [...dataInvoice]

        if (this.data_head) {
          this.data_head.head_val = false;
        }
      }
    },
    async getView(){
      try {
        this.$vs.loading();

        let month = "00"
        let year = "2022"
        if (this.formMonth.inputs.value) {
          month = this.formMonth.inputs.value.getMonth()
        }
        if (this.formYear.inputs.value) {
          year = this.formYear.inputs.value.getFullYear()
        }

        const params = {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          invoice_type: 4, // Tax Invoice DN Claim MIX Type
          tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
        };

        const response = await this.$http
          .get(`api/v1/master/tax-invoice/view-invoice-export`, { params })

        if (response.code != 200) {
          throw new Error(response.message)
        }

        return response.data

      } catch (error) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: error,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    formInputYear() {
      return {
        disabled: false,
        validate: "required",
        name: "year",
        placeholder: "Select Year",
        value: "",
        type: "date",
        min_view: "year",
        format: 'yyyy',
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    formInputMonth() {
      return {
        disabled: false,
        validate: "required",
        name: "month",
        placeholder: "Select Month",
        value: "",
        type: "date",
        min_view: "month",
        format: 'MM',
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    oninput(data, type) {
      if (type === "itemref") {
        let total_sn = 0;
        if (data.name === "start_number") {
          total_sn =
            this.itemRefs[data.id].inputs[5].components.value - data.value;
          if (total_sn < 0) {
            this.itemRefs[data.id].inputs[6].components.value = "" + 0;
            this.itemRefs[data.id].inputs[7].components.value = "" + 0;
            this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          } else {
            this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          }
        } else if (data.name === "end_number") {
          total_sn =
            data.value - this.itemRefs[data.id].inputs[4].components.value;
          if (total_sn < 0) {
            this.itemRefs[data.id].inputs[6].components.value = "" + 0;
            this.itemRefs[data.id].inputs[7].components.value = "" + 0;
            this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          } else {
            this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
            this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          }
        } else if (data.name === "checkbox") {
          if (data.head_val !== undefined) {
            this.data_head = data;
            if (data.head_val === true) {
              this.itemRefs.forEach((dt, i) => {
                this.itemRefs[i].inputs[1].components.value = true;
              });
            } else if (data.head_val === false) {
              this.itemRefs.forEach((dt, i) => {
                this.itemRefs[i].inputs[1].components.value = false;
              });
            }
          } else {
            if (data.value === false) {
              if (this.data_head) {
                this.data_head.head_val = false;
              }
            }
          }
        }
      }
    },
  }
}
</script>
